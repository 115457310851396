import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://github.com/hasparus/power-of-preconditions"
      }}>{`https://github.com/hasparus/power-of-preconditions`}</a></p>
    <hr></hr>
    <h3 {...{
      "id": "pitch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pitch",
        "aria-label": "pitch permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Pitch`}</h3>
    <p>{`TypeScript 3.7 brings us an amazing tool to concisely write preconditions
that can’t be described in a function signature.
With assertion signatures we can get rid of these ugly
`}<inlineCode parentName="p">{`if (not cool) throw new Error("totally not cool")`}</inlineCode>{` blocks.`}</p>
    <p>{`We’re gonna talk about partial and total functions,
returning vs throwing errors and I’ll show you how assertion signatures
make my React code safer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      